.die-char {
  line-height: 1em;
  padding: 0.5rem;
}

.dice-d4 {
  font-family: DPolyFourSider;
}

.dice-d6 {
  font-family: DPolySixSider;
}

.dice-d8 {
  font-family: DPolyEightSider;
}

.dice-d10 {
  font-family: DPolyTenSider;
}

.dice-d12 {
  font-family: DPolyTwelveSider;
}

.dice-d20 {
  font-family: DPolyTwentySider;
}