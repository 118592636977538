body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'DPolyFourSider';
  src: local('DPolyFourSider'), url(./fonts/DpolyFourSider-GM6P.ttf) format('truetype');
}

@font-face {
  font-family: 'DPolySixSider';
  src: local('DPolySixSider'), url(./fonts/DpolySixSider-jqRO.ttf) format('truetype');
}

@font-face {
  font-family: 'DPolyEightSider';
  src: local('DPolyEightSider'), url(./fonts/DpolyEightSider-JvWo.ttf) format('truetype');
}

@font-face {
  font-family: 'DPolyTenSider';
  src: local('DPolyTenSider'), url(./fonts/DpolyTenSider-z1Jw.ttf) format('truetype');
}

@font-face {
  font-family: 'DPolyTwelveSider';
  src: local('DPolyTwelveSider'), url(./fonts/DpolyTwelveSider-5KWZ.ttf) format('truetype');
}

@font-face {
  font-family: 'DPolyTwentySider';
  src: local('DPolyTwentySider'), url(./fonts/DpolyTwentySider-62jq.ttf) format('truetype');
}

.dice-dropdown-item {
  background-color: transparent;
}
